import React from "react";

const UserLogin = ({ getName, swicher }) => {
  return (
    <form>
      <div class='mb-3'>
        <label for='exampleInputEmail1' class='form-label'>
          Enter your name
        </label>
        <input
          type='email'
          class='form-control'
          id='exampleInputEmail1'
          aria-describedby='emailHelp'
          onChange={(e) => getName(e.target.value)}
        />
      </div>
      <button
        type='submit'
        class='btn btn-primary'
        onClick={() => swicher(true)}>
        Submit
      </button>
    </form>
  );
};

export default UserLogin;

import React, { useCallback, useEffect, useRef, useState } from "react";
import "./textEditor.css";
import "quill/dist/quill.snow.css";
import Quill from "quill";
import * as Y from "yjs";
import { WebsocketProvider } from "y-websocket";
import { QuillBinding } from "y-quill";
import QuillCursors from "quill-cursors";
import ListGroup from "react-bootstrap/ListGroup";

Quill.register("modules/cursors", QuillCursors);

const TOOLBAR_OPTIONS = {
  container: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["bold", "italic", "underline"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    [{ align: [] }],
    ["image", "blockquote", "code-block"],
  ],
};

const TextEditor = ({ userName }) => {
  const [quill, setQuill] = useState(null);
  const [doc, setDoc] = useState(null);
  const [provider, setProvider] = useState(null);
  const [awareness, setAwareness] = useState(null);
  const [userList, setUserList] = useState([]);
  let binding = null;

  const usercolors = [
    "#30bced",
    "#6eeb83",
    "#ffbc42",
    "#ecd444",
    "#ee6352",
    "#9ac2c9",
    "#8acb88",
    "#1be7ff",
  ];
  const myColor = usercolors[Math.floor(Math.random() * usercolors.length)];

  const wrapperRef = useCallback((wrapper) => {
    if (wrapper == null) return;
    wrapper.innerHTML = "";
    const editor = document.createElement("div");
    wrapper.append(editor);
    const get_Quill = new Quill(editor, {
      theme: "snow",
      modules: {
        toolbar: TOOLBAR_OPTIONS,
        history: {
          delay: 500,
          maxStack: 100,
          userOnly: true,
        },
        cursors: true,
      },
    });
    setQuill(get_Quill);
  }, []);
  useEffect(() => {
    if (quill == null) return;

    // Create a new Yjs document
    const ydoc = new Y.Doc();

    // Connect to the WebSocket server
    const wsProvider = new WebsocketProvider(
      "wss://node-collaboration-ocqxdiivfa-el.a.run.app",
      // "wss://demos.yjs.dev",
      "quill-demo-4",
      ydoc
    );
    wsProvider.on("status", (event) => {
      console.log("status", event.status); // logs "connected" or "disconnected"
    });

    // Get the Yjs text type for the Quill editor
    const ytext = ydoc.getText("quill");
    const yarray = ydoc.getArray("content");
    console.log("yarray", yarray);
    // Bind the Yjs text type to the Quill editor
    binding = new QuillBinding(ytext, quill, wsProvider.awareness);
    console.log("binding", binding);
    // Set state variables for the Yjs document, provider, and awareness
    setDoc(ydoc);
    setProvider(wsProvider);
    setAwareness(wsProvider.awareness);
    wsProvider.awareness.setLocalStateField("user", {
      name: userName,
      color: myColor,
    });
    // Clean up when the component is unmounted
    ydoc.on("update", (tra, doc) => {
      console.log("beforee transc", tra, doc);
    });
    return () => {
      ydoc.on("update", (arr, origin, tra, doc) => {
        console.log("return beforee transc", arr, "\n=>", origin, "\n=>", tra, doc);
      });
      binding.destroy();
      wsProvider.disconnect();
    };
  }, [quill]);

  // USEEFFECT TO GET EMBEDED HTML
  useEffect(() => {
    if (quill == null) return;

    const handler = (e) => {
      console.log("handler", e);
      const delta = quill.getContents();
      const text = quill.getText();
      const html = quill.root.innerHTML;
      console.log("delta", delta);
      console.log("text", text);
      console.log("html", html);
      // console.log("html", html);
      //  setText(text);
      //  setHtml(html);
    };

    quill.on("text-change", handler);
    return () => {
      quill.off("text-change", handler);
    };
  }, [quill]);

  useEffect(() => {
    if (awareness == null) return;
    awareness.on("change", () => {
      // Map each awareness state to a dom-string
      const strings = [];
      awareness.getStates().forEach((state) => {
        console.log(state);
        if (state.user) {
          strings.push({
            color: state.user.color,
            name: state.user.name,
          });
        }
        // document.querySelector("#users").innerHTML = strings.join("");
      });
      console.log("strings", strings);
      setUserList(strings);
    });
  }, [awareness, quill]);

  return (
    <>
      <div className='d-flex mt-4'>
        {
          <div className='d-flex flex-column'>
            <h3>Currently Editting :</h3>
            <ListGroup>
              {userList.map((i) => (
                <ListGroup.Item>
                  <div style={{ color: `${i.color}` }}>• {i.name}</div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>
        }
        <div className='container' ref={wrapperRef}></div>
      </div>
    </>
  );
};

export default TextEditor;

import React, { useState } from "react";
import TextEditor from "./collaboration/TextEditor";
import UserLogin from "./collaboration/UserLogin";

const App = () => {
  const [userName, setUserName] = useState("");
  const [moveToEditor, setMoveToEditor] = useState(false);
  return (
    <>
      {!moveToEditor ? (
        <UserLogin
          getName={(name) => setUserName(name)}
          swicher={(data) => setMoveToEditor(data)}
        />
      ) : (
        <TextEditor userName={userName} />
      )}
    </>
  );
};

export default App;
